import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Typography, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const names = [
  "Lactose intolerance",
  "Vegan Diet",
  "Pescatarian",
  "nuts",
  "Flexitarian",
  "Gluten-Free",
  "Low-Carb",
  "Low-Fat",
  "DASH",
  "Raw Food",
  "Intermittent Fasting",
  "Ayurvedic",
  "Macrobiotic",
  "Ketogenic",
  "Whole30",
  "Mediterranean",
];

function getStyles(name, deitary, theme) {
  return {
    fontWeight:
      deitary.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DietaryOptions = ({ updateDietary }) => {
  const theme = useTheme();
  const [deitary, setDietary] = React.useState([]);

  useEffect(() => {
    updateDietary(deitary);
  }, [deitary]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDietary(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // console.log(deitary)
  return (
    <>
      <Typography sx={{ fontWeight: 500, fontSize: "12px", mb: 1 }}>
        Do any members of your group have specific dietary restrictions?
      </Typography>
      <Autocomplete
        multiple
        id="tags-filled"
        options={names.map((option) => option)}
        defaultValue={[names[13]]}
        value={deitary}
        onChange={(event, newValue) => {
          setDietary(newValue);
        }}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                variant="outlined"
                label={option}
                key={key}
                {...tagProps}
                sx={{ fontSize: "10px" }}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: "8px",
              },
            }}
            // variant="filled"

            placeholder="Dietary  options"
          />
        )}
      />
    </>
  );
};

export default DietaryOptions;
