import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { getOpeningHours, getRestaurantDetails } from "../../api";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import {
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Popover,
  ListItemButton,
  LinearProgress,
  Button,
  Drawer,
  SwipeableDrawer,
  Paper,
  Avatar,
  ThemeProvider,
} from "@mui/material";
import logo from "../../Assets/logo/logo.svg";
import logogrey from "../../Assets/logo/logo-icon-grey.svg";
import BounceLoader from "react-spinners/BounceLoader";
import Calender from "../../Main/Components/Common/Calendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RiArrowDropDownFill } from "react-icons/ri";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import CarouselWidget from "../../Main/Components/Common/CarouselWidget";
import OpenTag from "../../Main/Components/Common/OpenTag";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoClockFill } from "react-icons/go";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import SEO from "../../utils/SEO";
import { PiPicnicTableLight } from "react-icons/pi";
import { MdEventRepeat } from "react-icons/md";
import { Theme, ThemeTerrace } from "../../Themes";
import terraceLogo from "../../Assets/logo/terrace-logo.jpg";

const daily_events = [
  {
    day: "Tue",
    name: "Family Meeting",
    description: "Prepared reservation for parties of 1 of 8",
  },
  {
    day: "Wed",
    name: "Classic Wednesday ",
    description: "Prepared reservation for parties of 1 of 8",
  },
  {
    day: "Thu",
    name: "Live Thursdays",
    description: "Prepared reservation for parties of 1 of 8",
  },
  {
    day: "Fri",
    name: "Lift Off Friday",
    description: "Prepared reservation for parties of 1 of 8",
  },
  {
    day: "Sat",
    name: "RoofTop Mix ",
    description: "Prepared reservation for parties of 1 of 8",
  },
  {
    day: "Sun",
    name: "Sip, Paint & Roast",
    description: "Prepared reservation for parties of 1 of 8",
  },
];

const ReservationWidget = () => {
  const { name } = useParams();
  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openingHours, setOpeningHours] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedFloor, setSelectedFloor] = useState(null);

  const [IS_TERRACE, setIsTerrace] = useState(false);
  useEffect(() => {
    if (name === "the-terrace-lagos") {
      setIsTerrace(true);
    }
  }, [name]);
  const override = {
    display: "block",
    margin: "auto",
    borderColor: IS_TERRACE ? "#013709" : "#BC172F",
  };
  useEffect(() => {
    setSelectedTime(selectedTime);
  }, [selectedTime]);

  console.log(selectedTime);
  const { enqueueSnackbar } = useSnackbar();

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const pid = open ? "simple-popover" : undefined;
  const [date, setDate] = useState(toDate);

  useEffect(() => {
    setDate(toDate);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetRestaurantDetails = async () => {
    setLoading(true);
    await getRestaurantDetails(name)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setRestaurant(res?.data?.result[0]);
          setSelectedFloor(res.data?.result[0]?.FloorPlans[0]?.Id);
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

  const [guest, setGuest] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [timeError, setTimeError] = useState(
    "  No Available Time for the selected date"
  );

  const [fullyBooked, setFullyBooked] = useState(false);

  const handleGetTime = async () => {
    setIsLoading(true);
    setOpeningHours(null);
    await getOpeningHours(restaurant?.RestaurantId, date, guest, selectedFloor)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setOpeningHours(res?.data?.result[0]?.GroupedTime);
          if (res?.data?.success_message === "Fully booked") {
            setFullyBooked(true);
          } else {
            setFullyBooked(false);
          }
        } else {
          setOpeningHours(null);
          if (res?.data?.error_message === "Fully booked") {
            setFullyBooked(true);
          } else {
            setFullyBooked(false);
            handleAlert("error", `${res?.data?.error_message}`);

            setTimeError(res?.data?.error_message);
          }
        }
      })
      .catch((err) => {
        handleAlert("error", `${err?.message}`);

        setIsLoading(false);
        setOpeningHours(null);
      });
  };

  useEffect(() => {
    if (restaurant?.RestaurantId) {
      handleGetTime();
    }
  }, [name, date, restaurant, guest, selectedFloor]);

  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => {
    setOpenDrawer(newOpen);
  };

  const EVENTFUL = name === "mercury lagos";

  const guestUrl = `/${name}/reservation/guest-booking?PartySize=${guest}&time=${selectedTime}&ReservationDay=${date}&deposit=${restaurant?.DepositPerPerson}&floor=${selectedFloor}&`;

  const userUrl = `/reservation/user?restaurantName=${name}&guest=${guest}&time=${selectedTime}&date=${date}&deposit=${restaurant?.DepositPerPerson}&floor=${selectedFloor}&`;

  console.log(IS_TERRACE, "Terrace");

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SEO
        title={`${name} | Make a Reservation`}
        name="Reisty"
        type="article"
      />
      <ThemeProvider theme={IS_TERRACE ? ThemeTerrace : Theme}>
        <Box
          className="hide_scrollbar"
          sx={{
            backgroundColor: IS_TERRACE ? "#fff" : "rgba(188, 23, 47, 0.05)",
            minHeight: "100vh",
            backgroundSize: "contain",
            boxSizing: "border-box",
            width: "100vw",
          }}
        >
          <Box
            className="hide_scrollbar"
            sx={{
              margin: "0 auto",
              width: { xl: "30%", lg: "40%", md: "50%", sm: "65%", xs: "100%" },
              bgcolor: IS_TERRACE ? "#fbf4e3" : "#fff",
              minHeight: "100vh",
              boxSizing: "border-box",
              pb: 4,
            }}
          >
            {loading ? (
              <>
                <Box
                  sx={{
                    height: "100vh",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <BounceLoader
                    color={IS_TERRACE ? "#013709" : "#BC172F"}
                    loading={true}
                    cssOverride={override}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    pt: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={IS_TERRACE ? terraceLogo : logo} width={70} />
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      px: 3,
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                        overflow: "hidden",
                      }}
                    >
                      Make a Reservation at
                      <span
                        style={{ textTransform: "uppercase", fontWeight: 700 }}
                      >
                        {" "}
                        {name}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                        overflow: "hidden",
                        fontSize: "12px",
                        mt: 0.5,
                      }}
                    >
                      Opens from{" "}
                      <b> {restaurant?.OpenFrom?.substring(0, 8) || "--"}</b>
                    </Typography>
                    {/* <OpenTag hoursString={restaurant?.OpenFrom} /> */}
                  </Box>
                  <Box sx={{ mt: 2, width: "100%" }}>
                    <CarouselWidget
                      images={restaurant?.Photos}
                      description={restaurant?.Description}
                      title={restaurant?.RestaurantName}
                    />
                  </Box>
                </Box>
                <Box
                  className="hide_scrollbar"
                  sx={{
                    margin: "0 auto",
                    width: "90%",
                    mt: 3,
                    overflow: "scroll",
                  }}
                >
                  {activeStep === 0 && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <InputLabel>Number of Guest(s)</InputLabel>
                            <TextField
                              value={`${guest}  Guest`}
                              margin="dense"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        if (guest > 1) {
                                          setGuest(guest - 1);
                                        }
                                      }}
                                      sx={{
                                        border: "1px solid ",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <FaMinus style={{ fontSize: "12px" }} />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => setGuest(guest + 1)}
                                      sx={{
                                        border: "1px solid ",
                                        borderRadius: "5px",
                                        ml: 1,
                                      }}
                                    >
                                      <FaPlus style={{ fontSize: "12px" }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontSize: "12px",
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <InputLabel>Date</InputLabel>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                border: "1px solid #d7d7d7",
                                px: 2,
                                mt: 1,
                                pb: 0.5,
                                boxSizing: "border-box",
                                borderRadius: "8px",
                                height: "50px",
                              }}
                              aria-describedby={pid}
                              onClick={handleClick}
                            >
                              <Box>
                                <Typography
                                  sx={{ fontWeight: 500, fontSize: "12px" }}
                                >
                                  {formatDate(date)}
                                </Typography>
                              </Box>
                              <RiArrowDropDownFill
                                style={{ fontSize: "20px" }}
                              />
                            </Box>
                          </Box>
                        </Grid>

                        {/* CURRENT EXPERIENCE =============== */}
                        {restaurant?.Events?.length > 0 && (
                          <>
                            <TodayExperience
                              events={restaurant?.Events}
                              date={date}
                            />
                          </>
                        )}

                        {EVENTFUL && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                              {daily_events.map((event, index) => {
                                const matching_day =
                                  event.day === formatDate(date).split(",")[0];

                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${
                                        restaurant?.Photos &&
                                        restaurant?.Photos[0]?.Link
                                      }')`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      mt: 1,
                                      display: matching_day ? "flex" : "none",
                                      p: 2,
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 300,
                                        color: "#fff",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Today's Event
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontFamily: "optima",
                                        color: "#fff",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {event.name}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Grid>
                        )}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box>
                            <InputLabel>Time</InputLabel>
                            <Grid container spacing={2} sx={{ mt: 0 }}>
                              {isLoading && (
                                <Box align="center" sx={{ width: "100%" }}>
                                  <LinearProgress sx={{ width: "50%" }} />
                                  <Typography
                                    sx={{
                                      color: "primary.main",
                                      fontSize: "10px",
                                    }}
                                  >
                                    Checking for Available Time...
                                  </Typography>
                                </Box>
                              )}

                              {!openingHours ||
                              openingHours.length === 0 ||
                              (openingHours[0].length === 0 &&
                                openingHours[1]?.length === 0) ? (
                                <>
                                  {!isLoading && (
                                    <>
                                      <Box sx={{ p: 1, width: "100%", mt: 1 }}>
                                        <Typography
                                          sx={{
                                            textAlign: "center",
                                            fontWeight: 600,
                                            color: IS_TERRACE
                                              ? "#013709"
                                              : "#BC172F",
                                          }}
                                        >
                                          {timeError}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            textAlign: "center",
                                            fontWeight: 300,
                                            fontSize: "12px",
                                          }}
                                        >
                                          You can try selecting another date or
                                          guest size
                                        </Typography>
                                      </Box>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {fullyBooked ? (
                                    <>
                                      {openingHours &&
                                        openingHours[0]?.map((time, index) => (
                                          <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            sm={4}
                                            xs={4}
                                            key={index}
                                          >
                                            <ListItemButton
                                              key={index}
                                              onClick={() => {
                                                setSelectedTime(time?.Time);
                                              }}
                                              selected={
                                                selectedTime === time?.Time
                                              }
                                              sx={{
                                                border: "1px solid #d7d7d7",
                                                borderRadius: "8px",
                                                bgcolor: IS_TERRACE
                                                  ? "#013709"
                                                  : "#BC172F",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                color: "#fff",
                                                justifyContent: "center",
                                                "&.Mui-selected, &:hover ": {
                                                  border: "1px solid",
                                                  borderColor: IS_TERRACE
                                                    ? "#013709"
                                                    : "#bc172f",
                                                  bgcolor:
                                                    IS_TERRACE && "#013709",
                                                  color: IS_TERRACE
                                                    ? "#fbf4e3"
                                                    : "#BC172F",
                                                },
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {" "}
                                                {time?.Time}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "8px",
                                                  whiteSpace: "nowrap",
                                                  mt: -1,
                                                }}
                                              >
                                                {" "}
                                                Reserved
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "8px",
                                                  whiteSpace: "nowrap",
                                                  mt: -1,
                                                }}
                                              >
                                                {" "}
                                                Join Waitlist
                                              </Typography>
                                            </ListItemButton>
                                          </Grid>
                                        ))}
                                      {openingHours &&
                                        openingHours[1]?.map((time, index) => (
                                          <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            sm={4}
                                            xs={4}
                                            key={index}
                                          >
                                            <ListItemButton
                                              key={index}
                                              onClick={() => {
                                                setSelectedTime(time?.Time);
                                              }}
                                              selected={
                                                selectedTime === time?.Time
                                              }
                                              sx={{
                                                border: "1px solid #d7d7d7",
                                                borderRadius: "8px",
                                                bgcolor: IS_TERRACE
                                                  ? "#013709"
                                                  : "#BC172F",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                color: "#fff",
                                                justifyContent: "center",
                                                "&.Mui-selected, &:hover ": {
                                                  border: "1px solid ",
                                                  borderColor: IS_TERRACE
                                                    ? "#013709"
                                                    : "#bc172f",
                                                  bgcolor:
                                                    IS_TERRACE && "#013709",
                                                  color: IS_TERRACE
                                                    ? "#fbf4e3"
                                                    : "#BC172F",
                                                },
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {" "}
                                                {time?.Time}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "8px",
                                                  whiteSpace: "nowrap",
                                                  mt: -1,
                                                }}
                                              >
                                                {" "}
                                                Reserved
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "8px",
                                                  whiteSpace: "nowrap",
                                                  mt: -1,
                                                }}
                                              >
                                                {" "}
                                                Join Waitlist
                                              </Typography>
                                            </ListItemButton>
                                          </Grid>
                                        ))}
                                    </>
                                  ) : (
                                    <>
                                      {openingHours &&
                                        openingHours[0]?.map((time, index) => {
                                          const IsAvailable = time?.IsAvailable;
                                          return (
                                            <Grid
                                              item
                                              lg={3}
                                              md={3}
                                              sm={4}
                                              xs={4}
                                              key={index}
                                            >
                                              {IsAvailable ? (
                                                <ListItemButton
                                                  disabled={!IsAvailable}
                                                  onClick={() => {
                                                    setSelectedTime(time?.Time);
                                                  }}
                                                  selected={
                                                    selectedTime === time?.Time
                                                  }
                                                  sx={{
                                                    border: "1px solid #d7d7d7",
                                                    borderRadius: "8px",
                                                    "&.Mui-selected": {
                                                      border: "1px solid",
                                                      borderColor: IS_TERRACE
                                                        ? "#013709"
                                                        : "#bc172f",
                                                      bgcolor:
                                                        IS_TERRACE && "#013709",
                                                      color: IS_TERRACE
                                                        ? "#fbf4e3"
                                                        : "#BC172F",
                                                    },
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "12px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {" "}
                                                    {time?.Time}
                                                  </Typography>
                                                </ListItemButton>
                                              ) : (
                                                <ListItemButton
                                                  disabled
                                                  onClick={() => {
                                                    setSelectedTime(time?.Time);
                                                  }}
                                                  selected={
                                                    selectedTime === time?.Time
                                                  }
                                                  sx={{
                                                    border: "1px solid #d7d7d7",
                                                    borderRadius: "8px",
                                                    // bgcolor: "#BC172F",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    // color: "#fff",
                                                    justifyContent: "center",
                                                    "&.Mui-selected, &:hover ": {
                                                      border: "1px solid ",
                                                      borderColor: IS_TERRACE
                                                        ? "#013709"
                                                        : "#bc172f",
                                                      bgcolor:
                                                        IS_TERRACE && "#013709",
                                                      color: IS_TERRACE
                                                        ? "#fbf4e3"
                                                        : "#BC172F",
                                                    },
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "10px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {" "}
                                                    {time?.Time}
                                                  </Typography>
                                                  {/* <Typography
                                              sx={{
                                                fontSize: "8px",
                                                whiteSpace: "nowrap",
                                                mt: -1,
                                              }}
                                            >
                                              {" "}
                                              Reserved
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "8px",
                                                whiteSpace: "nowrap",
                                                mt: -1,
                                              }}
                                            >
                                              {" "}
                                              Join Waitlist
                                            </Typography> */}
                                                </ListItemButton>
                                              )}
                                            </Grid>
                                          );
                                        })}
                                      {openingHours &&
                                        openingHours[1]?.map((time, index) => {
                                          const IsAvailable = time?.IsAvailable;
                                          return (
                                            <Grid
                                              item
                                              lg={3}
                                              md={3}
                                              sm={4}
                                              xs={4}
                                              key={index}
                                            >
                                              {IsAvailable ? (
                                                <ListItemButton
                                                  disabled={!IsAvailable}
                                                  onClick={() => {
                                                    setSelectedTime(time?.Time);
                                                  }}
                                                  selected={
                                                    selectedTime === time?.Time
                                                  }
                                                  sx={{
                                                    border: "1px solid #d7d7d7",
                                                    borderRadius: "8px",
                                                    "&.Mui-selected": {
                                                      border: "1px solid ",
                                                      borderColor: IS_TERRACE
                                                        ? "#013709"
                                                        : "#bc172f",
                                                      bgcolor:
                                                        IS_TERRACE && "#013709",
                                                      color: IS_TERRACE
                                                        ? "#fbf4e3"
                                                        : "#BC172F",
                                                    },
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "12px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {" "}
                                                    {time?.Time}
                                                  </Typography>
                                                </ListItemButton>
                                              ) : (
                                                <ListItemButton
                                                  // onClick={() => {
                                                  //   setSelectedTime(time?.Time);
                                                  // }}
                                                  selected={
                                                    selectedTime === time?.Time
                                                  }
                                                  disabled
                                                  sx={{
                                                    border: "1px solid #d7d7d7",
                                                    borderRadius: "8px",

                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    // color: "#fff",
                                                    justifyContent: "center",
                                                    "&.Mui-selected, &:hover ": {
                                                      border: "1px solid",
                                                      borderColor: IS_TERRACE
                                                        ? "#013709"
                                                        : "#bc172f",
                                                      bgcolor:
                                                        IS_TERRACE && "#013709",
                                                      color: IS_TERRACE
                                                        ? "#fbf4e3"
                                                        : "#BC172F",
                                                    },
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "10px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {" "}
                                                    {time?.Time}
                                                  </Typography>
                                                  {/* <Typography
                                              sx={{
                                                fontSize: "8px",
                                                whiteSpace: "nowrap",
                                                mt: -1,
                                              }}
                                            >
                                              {" "}
                                              Reserved
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "8px",
                                                whiteSpace: "nowrap",
                                                mt: -1,
                                              }}
                                            >
                                              {" "}
                                              Join Waitlist
                                            </Typography> */}
                                                </ListItemButton>
                                              )}
                                            </Grid>
                                          );
                                        })}
                                    </>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box sx={{ mt: 4, mb: 5 }}>
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{ borderRadius: "12px", py: 2 }}
                          disabled={!guest || !date || !selectedTime}
                          onClick={() => {
                            if (restaurant?.FloorPlans?.length == 1) {
                              if (isAuthenticated) {
                                navigate(
                                  fullyBooked
                                    ? `${userUrl}waitlist=true`
                                    : `${userUrl}waitlist=false`
                                );
                              } else {
                                setActiveStep(1);
                              }
                            } else {
                              toggleDrawer(true);
                            }
                          }}
                        >
                          Proceed
                        </Button>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Box>
                        <Button
                          onClick={() => setActiveStep(0)}
                          startIcon={<IoArrowBackCircleOutline />}
                        >
                          Back
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          mt: 2,
                          border: "1px solid #d7d7d7",
                          borderRadius: "8px",
                        }}
                      >
                        <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                          <Typography color="primary" sx={{ fontWeight: 700 }}>
                            Reservation Details
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            columnGap: 2,
                            borderBottom: "1px solid #d7d7d7",
                          }}
                        >
                          <Box
                            sx={{
                              background: `url('${restaurant?.Photos[0]?.Link}')`,
                              height: "100px",
                              width: "100px",
                              borderRadius: "8px",
                              backgroundSize: "cover",
                            }}
                          />
                          <Box>
                            <Typography sx={{ fontWeight: 600 }}>
                              {restaurant?.RestaurantName}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                                mt: 1,
                              }}
                            >
                              <BsCalendar2CheckFill
                                style={{ fontSize: "14px" }}
                              />
                              <Typography sx={{ fontSize: "12px" }}>
                                {formatDate(date)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                                mt: 0.4,
                              }}
                            >
                              <BsFillPeopleFill style={{ fontSize: "14px" }} />
                              <Typography
                                sx={{ fontSize: "12px" }}
                              >{`${guest} Guest`}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                                mt: 0.4,
                              }}
                            >
                              <GoClockFill style={{ fontSize: "14px" }} />
                              <Typography sx={{ fontSize: "12px" }}>
                                {selectedTime}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Link
                            to={"/login"}
                            state={{
                              redirectTo: fullyBooked
                                ? `${userUrl}waitlist=true`
                                : `${userUrl}waitlist=false`,
                            }}
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{ borderRadius: "12px", py: 2 }}
                            >
                              Login with Reisty
                            </Button>
                          </Link>
                          <Typography sx={{ textAlign: "center", my: 2 }}>
                            or
                          </Typography>
                          <Link
                            to={
                              fullyBooked
                                ? `${guestUrl}waitlist=true`
                                : `${guestUrl}waitlist=false`
                            }
                          >
                            <Button
                              variant="outlined"
                              fullWidth
                              sx={{ borderRadius: "12px", py: 2 }}
                            >
                              Continue as Guest
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
            {IS_TERRACE && (
              <Typography sx={{ textAlign: "center", mt: 4, color: "#b8b3a5" }}>
                Powered by <img src={logogrey} width={25} /> Reisty
              </Typography>
            )}
          </Box>

          <SwipeableDrawer
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
            anchor={"bottom"}
            sx={{
              flexShrink: 0,
              boxSizing: "border-box",
              "& .MuiDrawer-paper": {
                width: {
                  xl: "30%",
                  lg: "35%",
                  md: "45%",
                  sm: "65%",
                  xs: "100%",
                },
                boxSizing: "border-box",
                margin: "0 auto",
                borderRadius: "10px 10px 0px 0px",
              },
            }}
          >
            <Box
              sx={{
                height: "65vh",
                bgcolor: IS_TERRACE ? "primary.main" : "#f5f5f5",
                margin: "0 auto",
                borderRadius: "10px 10px 0px 0px",
                width: "100%",
              }}
            >
              <Box sx={{ display: "grid", placeContent: "center", mt: 1 }}>
                <Box
                  onClick={() => toggleDrawer(false)}
                  sx={{
                    width: "70px",
                    height: "5px",
                    bgcolor: "#333",
                    borderRadius: "50px",
                  }}
                />
              </Box>

              <Box sx={{ mt: 4, boxSizing: "border-box", px: 4 }}>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  Select Seating Area
                </Typography>

                <Box
                  sx={{ mt: 3, height: "35vh", overflow: "scroll" }}
                  className="hide_scrollbar"
                >
                  {restaurant?.FloorPlans?.map((floor, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => setSelectedFloor(floor?.Id)}
                      selected={selectedFloor === floor?.Id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 0.8,
                        bgcolor: "#fff",
                      }}
                    >
                      <PiPicnicTableLight
                        style={{
                          fontSize: "50px",
                          color:
                            selectedFloor === floor?.Id && IS_TERRACE
                              ? "#013709"
                              : "#bc172f",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 500,
                          color: selectedFloor === floor?.Id && "primary.main",
                        }}
                      >
                        {floor?.Name}
                      </Typography>
                    </ListItemButton>
                  ))}
                </Box>

                <Box
                  sx={{
                    margin: "0 auto",
                    height: "100px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: 0,
                    bgcolor: "transparent",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "10px", py: 2, width: "230px" }}
                    disabled={
                      !guest ||
                      !date ||
                      !selectedTime ||
                      !selectedFloor ||
                      isLoading ||
                      !openingHours
                    }
                    onClick={() => {
                      if (isAuthenticated) {
                        navigate(
                          fullyBooked
                            ? `${userUrl}waitlist=true`
                            : `${userUrl}waitlist=false`
                        );
                      } else {
                        setOpenDrawer(false);
                        setActiveStep(1);
                      }
                    }}
                  >
                    {isLoading ? "checking..." : "Proceed"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </SwipeableDrawer>
        </Box>

        <Popover
          id={pid}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box>
            <Calender
              future={false}
              past={true}
              onChildValue={(value) => {
                setDate(value);
                setAnchorEl(null);
                console.log(date);
              }}
            />
          </Box>
        </Popover>
      </ThemeProvider>
    </>
  );
};

export default ReservationWidget;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}

const TodayExperience = ({ events, date }) => {
  const today = new Date().toISOString().split("T")[0];

  // Filter events happening today
  const todayEvents = events.filter((event) => event.EventDate === date);

  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box>
          {todayEvents.map((event, index) => {
            return (
              <Box
                key={index}
                sx={{
                  background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${event?.EventImage}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  mt: 1,

                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 300,
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    {event?.EventTime}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontFamily: "optima",
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    {event.EventName}
                  </Typography>
                </Box>
                <Box>
                  <a
                    href={`https://ticket.reisty.com/event/${event?.EventName}/${event?.RestaurantId}?source=web`}
                    target="_blank"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "10px",
                        borderRadius: "8px",
                        fontWeight: 300,
                      }}
                    >
                      Purchase ticket
                    </Button>
                  </a>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </>
  );
};
