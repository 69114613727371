import {
  Box,
  Grid,
  InputLabel,
  Typography,
  ListItemButton,
  Button,
  Divider,
  Skeleton,
  Checkbox,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../Main/Components/Custom/CustomInput";
import BookingData from "../../Assets/Dataa/BookingData";
import anime from "../../Assets/anime/done.json";
import Lottie from "react-lottie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  createReservation,
  createWaitlistOffline,
  getRestaurantDetails,
  manageReservationOffline,
} from "../../api";
import Loader from "../../Main/Components/Common/Loader";
import logo from "../../Assets/logo/logo.svg";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoClockFill } from "react-icons/go";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa6";
import { useSnackbar } from "notistack";
import DietaryOptions from "../../Main/Components/Common/DietaryOptions";
import PaystackPaymentButton from "../../Main/Components/Common/PaystackPaymentButton";
import { Helmet } from "react-helmet-async";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "@mui/material/Modal";
import { PiClockUserThin } from "react-icons/pi";
import { Theme, ThemeTerrace } from "../../Themes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 350, xs: 350 },
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "10px",
  maxHeight:'70vh',
  overflowY:'scroll'
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: anime,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const InformationWidgetUser = ({}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [Dietary, setDietary] = useState("");
  const [occasion, setOccasion] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);

  const [openWaitlist, setOpenWaitlist] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const {
    restaurantName,
    guest,
    time,
    date,
    deposit,
    floor,
    waitlist,
  } = queryParams;
  const user = useSelector((state) => state.user.user);

  console.log(typeof waitlist, "waitlist");
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    setFirstName(user?.first_name);
    setLastName(user?.last_name);
    setEmail(user?.email);
    setPhoneNumber(user?.phone_number);
  }, [user]);

  const handleCreateReservation = async (payStackTransactionId) => {
    setIsLoading(true);

    const guestsDetails = null;
    const NewsAndUpdates = true;
    const EmailsAndOffers = true;
    const useWithWalletBalance = true;
    const newDeposit = deposit * guest;
    await createReservation(
      restaurant?.RestaurantId,
      floor,
      date,
      time,
      guest,
      phoneNumber,
      newDeposit,
      payStackTransactionId,
      guestsDetails,
      occasion,
      Dietary?.join(", "),
      specialRequest,
      EmailsAndOffers,
      NewsAndUpdates,
      useWithWalletBalance
    )
      .then((res) => {
        if (res?.data?.status) {
          navigate(`/reservation/booked/${res?.data?.result[0]?.BookingId}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };
  const handleWaitlistReservation = async () => {
    setIsLoading(true);
    await createWaitlistOffline(
      restaurant?.RestaurantId,
      floor,
      date,
      time,
      firstName,
      lastName,
      phoneNumber,
      email,
      parseFloat(guest),
      occasion,
      Dietary?.join(", "),
      specialRequest,
      null,
      true
    )
      .then((res) => {
        if (res?.data?.status) {
          setOpenWaitlist(true);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };
  const handleCreateReservation2 = async () => {
    setIsLoading(true);

    const guestsDetails = null;
    const NewsAndUpdates = true;
    const EmailsAndOffers = true;
    const useWithWalletBalance = true;
    await createReservation(
      restaurant?.RestaurantId,
      floor,
      date,
      time,
      guest,
      phoneNumber,
      deposit,
      null,
      guestsDetails,
      occasion,
      Dietary?.join(", "),
      specialRequest,
      EmailsAndOffers,
      NewsAndUpdates,
      useWithWalletBalance,
      true
    )
      .then((res) => {
        if (res?.data?.status) {
          navigate(`/reservation/booked/${res?.data?.result[0]?.BookingId}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };

  const handleGetRestaurantDetails = async () => {
    setLoading(true);
    await getRestaurantDetails(restaurantName)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setRestaurant(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

  const [rulesChecked, setRulesChecked] = useState(false);

  const [IS_TERRACE, setIsTerrace] = useState(false);
  useEffect(() => {
    if (restaurantName === "the-terrace-lagos") {
      setIsTerrace(true);
    }
  }, [restaurantName]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ThemeProvider theme={IS_TERRACE ? ThemeTerrace : Theme}>
      {isLoading && <Loader />}
      {isSuccessful ? (
        <>
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 4,
              bgcolor: IS_TERRACE && "#fbf4e3" 
            }}
          >
            <Lottie options={defaultOptions} width={400} height={200} />
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Reservation Modified
            </Typography>
            <Typography>
              Please check your inbox for a confirmation email.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box align="center" sx={{ pt: 4,         bgcolor: IS_TERRACE && "#fbf4e3"  }}>
            <img src={logo} width={90} />
            <Typography sx={{ mt: 2, fontWeight: 700, fontSize: "16px" }}>
              Complete Reservation
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "0 auto",
              mt: 4,
                      bgcolor: IS_TERRACE && "#fbf4e3" ,
              width: {
                xl: "80%",
                lg: "80%",
                md: "90%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ p: 3, boxSizing: "border-box" }}>
                  <Box
                    sx={{
                      border: "1px solid #d7d7d7",
                      p: 2,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography sx={{ fontWeight: 700 }}>
                      Reservation Details
                    </Typography>
                    <Typography sx={{ mt: 2 }}>{restaurantName}</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 1,
                        }}
                      >
                        <BsCalendar2CheckFill style={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {formatDate(date)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 0.4,
                        }}
                      >
                        <BsFillPeopleFill style={{ fontSize: "14px" }} />
                        <Typography
                          sx={{ fontSize: "12px" }}
                        >{`${guest} Guest`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 0.4,
                        }}
                      >
                        <GoClockFill style={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {time}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 0.4,
                        }}
                      >
                        <FaUser style={{ fontSize: "14px" }} />

                        <Typography sx={{ fontSize: "12px" }}>
                          {`${user?.first_name} ${user?.last_name}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {deposit > 0 && (
                    <Box
                      sx={{
                        mt: 3,
                        borderRadius: "8px",
                        border: "1px solid #d7d7d7",
                      }}
                    >
                      <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                        <Typography color="primary" sx={{ fontWeight: 600 }}>
                          Reservation Deposit
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              textTransform: "uppercase",
                            }}
                          >{`${restaurantName} * ${guest}`}</Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            ₦{parseFloat(deposit).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            Total
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            ₦{parseFloat(deposit * guest).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            Amount Due
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            ₦{parseFloat(deposit * guest).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          bgcolor:IS_TERRACE? "#0137091a" : "#bc172f1a",
                          borderRadius: "0px 0px 8px 8px",
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          Disclaimer
                        </Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          This deposit will be deducted from your final bill
                          after the reservation has been concluded. In the event
                          you need to cancel your reservation, please ensure you
                          do so at least 12 hours before the reservation time,
                          and the deposit will be refunded to your bank account.
                          You will receive a reservation reminder 13 hours
                          before your reservation time.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Typography sx={{ fontWeight: 600, mb: 1, mt: 4 }}>
                    Lets help you plan your visit
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#333" }}>
                    To aid {restaurantName} Restaurant in organizing your visit,
                    kindly provide answers to the questions below.
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                      borderRadius: "10px",
                      border: "1px solid #d7d7d7",
                      p: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                      What occasion are you celebrating?
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        {BookingData?.map((item, index) => {
                          const isSelected = item?.title === occasion;
                          return (
                            <Grid item lg={6} xs={6} key={index}>
                              <ListItemButton
                                selected={isSelected}
                                onClick={() => setOccasion(item?.title)}
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  columnGap: 2,
                                  p: 2,
                                  border: "1px solid #d7d7d7",
                                  borderRadius: "8px",
                                  ...(isSelected && {
                                    border: "1px solid ",
                                    borderColor:IS_TERRACE ? "#013709" : "#BC172F"
                                  }),
                                }}
                              >
                                <img
                                  src={isSelected ? item?.icon2 : item?.icon1}
                                />
                                <Typography
                                  color={isSelected && "primary"}
                                  sx={{ fontSize: "12px" }}
                                >
                                  {item?.title}
                                </Typography>
                              </ListItemButton>
                            </Grid>
                          );
                        })}
                      </Grid>

                      <Box sx={{ mt: 3 }}>
                        <DietaryOptions
                          updateDietary={(value) => setDietary(value)}
                        />
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Do have any special request?
                        </Typography>

                        <CustomInput
                          value={specialRequest}
                          onChange={(e) => setSpecialRequest(e.target.value)}
                          placeholder="Your  input here"
                          multiline
                          fullWidth
                          margin="dense"
                          minRows={2}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ p: 3, boxSizing: "border-box", pt: 0 }}>
                  <Box
                    sx={{
                      mt: 3,
                      p: 2,
                      border: "1px solid #d7d7d7",
                      borderRadius: "8px",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Cancellation policy
                      </Typography>
                      <Typography
                        sx={{ fontSize: "10px", mt: 1, textAlign: "justify" }}
                      >
                        We understand that circumstances can change, and if you
                        need to cancel, there won't be any charges. However, we
                        kindly request that you notify us at least 24 hours
                        prior to your scheduled time.
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
                      Reservation being held for <span>{time}</span>
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    {loading ? (
                      <>
                        <Skeleton width={"100%"} height={100} />
                      </>
                    ) : (
                      <>
                        {restaurant?.Experience?.HouseRules && (
                          <>
                            <Box
                              sx={{
                                mt: 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                checked={rulesChecked}
                                onClick={handleOpen}
                              />{" "}
                              <Typography sx={{ fontWeight: 500 }}>
                                Click to view and accept the house rules terms.
                              </Typography>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  {waitlist === "true" ? (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 5, borderRadius: "50px", py: 2 }}
                      onClick={handleWaitlistReservation}
                    >
                      Join the waitlist
                    </Button>
                  ) : (
                    <>
                      {deposit > 0 ? (
                        <>
                          <Box
                            sx={{
                              p: 2,
                              border: "1px solid #d7d7d7",
                              borderRadius: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                pb: 2,
                                // display: "flex",
                                // alignItems: "center",
                                // justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ fontWeight: 400 }}>
                                Payment Method
                              </Typography>
                              <Typography sx={{ fontWeight: 600 }}>
                                Pay Online
                              </Typography>
                            </Box>
                          </Box>
                          <PaystackPaymentButton
                            first_name={user?.first_name}
                            last_name={user?.first_name}
                            email={user?.email}
                            amount={deposit * guest}
                            onSuccessAction={handleCreateReservation}
                            label={"Make Payment"}
                          />
                        </>
                      ) : (
                        <Button
                          disabled={
                            !firstName ||
                            !lastName ||
                            !email ||
                            !phoneNumber ||
                            (restaurant?.Experience?.HouseRules &&
                              !rulesChecked)
                          }
                          onClick={handleCreateReservation2}
                          variant="contained"
                          fullWidth
                          sx={{ mt: 5, borderRadius: "50px", py: 2 }}
                        >
                          Complete Reservation
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            House Rules
          </Typography>
          <Divider sx={{ mt: 1 }} />
          <Box sx={{ mt: 2, mx: -3 }}>
            <ReactQuill
              value={restaurant?.Experience?.HouseRules}
              readOnly={true}
              theme={null} // Disable theme
              modules={{ toolbar: false }}
              style={{
                fontFamily: "outfit",

                padding: "0%",
              }}
              className="ql-editor" // Apply custom CSS class
            />
          </Box>

          <Box align="right" sx={{ mt: 3 }}>
            <Button
              variant="contained"
              onClick={(e) => {
                setRulesChecked(true);
                handleClose();
              }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openWaitlist}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ mt: 2 }} align="center">
            <PiClockUserThin style={{ fontSize: "60px", color: "#FFA500 " }} />
            <h3
              style={{
                fontFamily: "optima",
                textAlign: "center",
                fontSize: "30px",
              }}
            >
              You’re Now on the Waitlist
            </h3>
            <Typography sx={{ mt: -3 }}>
              We’re preparing to accommodate you. Please wait for further
              updates.
            </Typography>
          </Box>

          <Box align="center" sx={{ mt: 5 }}>
            <Button
              variant="contained"
              sx={{ width: "230px", height: "45px", borderRadius: "8px" }}
              onClick={(e) => {
                navigate(`/${restaurantName}/reservation`);
              }}
            >
              Thank You
            </Button>
          </Box>
        </Box>
      </Modal>
      </ThemeProvider>
    </>
  );
};

export default InformationWidgetUser;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
